<div nz-row id="seccion-buscador">
    <div nz-col [nzXs]="24">
        <h1>Sección Noticias</h1>
        <!--Slogan que cambiaran dinamicamente de acuerdo al filtro(marca) que se este aplicando-->
        <div *ngIf="marca === 'toyota'">
            <h2><span style="color: #bf0026;">TOYOTA</span></h2>
            <h2>Supremacía en el camino.</h2>
        </div>
        <div *ngIf="marca === 'lexus'">
            <h2><span style="color: #cccccc; font-weight: 200;text-shadow: #fff 1px 1px 2px;">LEXUS</span></h2>
            <h2>Experiencie Amazing.</h2>
        </div>
        <div *ngIf="marca === 'hino'">
            <h2><span style="color: #bf0026;">HINO</span></h2>
            <h2>El mejor aliado para tu negocio.</h2>
        </div>
        <div *ngIf="marca === 'yamaha'">
            <h2><span style="color: #ff0100;">YAMAHA</span></h2>
            <h2>Domina tu energía.</h2>
        </div>        
    </div>
    <!--Inicia la sección del buscador-->
    <div nz-col [nzXs]="{span:20, offset:2}" [nzLg]="{span:4, offset:18}" id="buscador">
      <!--Input para el buscador  el cual guardara todo cambio en la variable search-->
        <nz-input-group nzSuffixIcon="anticon anticon-search">
            <input type="text" [(ngModel)]="search" (ngModelChange)="buscar()" nz-input placeholder="buscar...">
        </nz-input-group>
      <!--Lista generada con los diferentes titulos de las noticias que coincidan con el filtro de la variable search-->
        <nz-list [nzDataSource]="data" [nzRenderItem]="item" [nzItemLayout]="'horizontal'" *ngIf="visibleSearch">
            <ng-template #item let-item>
                <nz-list-item>
                    <nz-list-item-meta
                        [nzTitle]="nzTitle">
                            <ng-template #nzTitle>
                                <a [href]="[ '/' + item.marca + '/' + item.id_noticia + '-' + item.link]">{{item.title}}</a>
                            </ng-template>
                    </nz-list-item-meta>
                </nz-list-item>
            </ng-template>
        </nz-list>
    </div>
</div>
