import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: ':filtro', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'filtros', loadChildren: () => import('./filtros/filtros.module').then(m => m.FiltrosModule) },
  { path: 'buscador', loadChildren: () => import('./buscador/buscador.module').then(m => m.BuscadorModule) },
  { path: ':filtro/:noticia', loadChildren: () => import('./noticia/noticia.module').then(m => m.NoticiaModule), pathMatch: 'full' }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
