<div class="nz-row">
    <div nz-col [nzXs]="{span:20, offset:2}">
        <h1 class="title"> <span>{{marcaActiva}}</span> Noticias</h1>
        <div class="menu-marcas">
            <ul nz-menu nzMode="horizontal">
                <li nz-menu-item>
                    <a routerLink="/" class="filtrosCategoriasBtn" (click)="selectMarca('', 0)">                
                        TODAS LAS NOTICIAS
                    </a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <a routerLink="/lexus" class="filtrosCategoriasBtn" (click)="selectMarca('lexus', 1)">                
                        LEXUS
                    </a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <a routerLink="/toyota" class="filtrosCategoriasBtn" (click)="selectMarca('toyota', 2)">                
                        TOYOTA
                    </a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <a routerLink="/hino" class="filtrosCategoriasBtn" (click)="selectMarca('hino', 3)">
                        HINO
                    </a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <a routerLink="/yamaha" class="filtrosCategoriasBtn" (click)="selectMarca('yamaha', 4)">
                        YAMAHA
                    </a>
                </li>
                
            </ul>
        </div>
    </div>
</div>


