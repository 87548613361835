import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FiltrosRoutingModule } from './filtros-routing.module';
import { FiltrosComponent } from './filtros.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzMenuModule } from 'ng-zorro-antd/menu';


@NgModule({
  exports:[
    FiltrosComponent
  ],
  declarations: [
    FiltrosComponent
  ],
  imports: [
    CommonModule,
    FiltrosRoutingModule,
    NzGridModule,
    NzMenuModule
  ]
})
export class FiltrosModule { }
