<div nz-row id="header" (window:resize)="resize()">
  <!--Menu del header para Desktop-->
    <div nz-col nzXs="0" [nzSm]="{span:20, offset:2}" [nzXl]="{span:20, offset:2}" id="menu" class="menu-desktop" *ngIf="!mobile">
        <a routerLink="/"><img src="../../assets/images/logo-toyota.png" class="brand"/></a>
        <ul nz-menu [nzMode]="'horizontal'" id="menu-desktop">
            <li nz-menu-item (select)="true"><a routerLink="/">INICIO</a></li>
            <li nz-menu-item>
                <a href="https://www.toyosa.com" target="_blank" rel="noopener noreferrer">TOYOSA</a>
            </li>
        </ul>
    </div>
  <!--Menu Hamburguesa para la versión Mobile-->
    <div nz-col [nzXs]="{span:8, offset: 6}" nzSm="0" *ngIf="mobile" style="margin-top: 2%; text-align: center;">
        <a routerLink="/"><img src="../../assets/images/logo-toyota.png" class="brand"/></a>
        <a (click)="toggleSubmenu()"><i class="anticon anticon-menu-{{icono}}"></i></a>
    </div>
</div>
<!--Submenu para la versión mobile-->
<div nz-row *ngIf="visibleSubmenu" id="submenu">
    <ul nz-menu [nzMode]="'vertical'" id="menu-mobile">
        <li nz-menu-item>
            <a routerLink="/">INICIO</a>
        </li>
        <li nz-menu-item>
            <a href="https://www.toyosa.com" target="_blank" rel="noopener noreferrer">TOYOSA</a>
        </li>
    </ul>
</div>
