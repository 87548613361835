import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @Input() logos: any;
  mobile:any;
  visibleSubmenu;
  icono:any;

  constructor() {        
      this.resize();
      // Inicializa el submenu siempre oculto
      this.visibleSubmenu = false;
  }
  // Función que muestra ú oculta el submenu del header
  toggleSubmenu() {
      if (this.visibleSubmenu) {
          this.icono = 'unfold';
          this.visibleSubmenu = false;
      } else {
          this.icono = 'fold';
          this.visibleSubmenu = true;
      }
  }

  resize() {
      // Detecta la resolución actual de la pantalla y activa o desactiva la versión mobile del header
      if (window.innerWidth > 767) {
          this.mobile = false;
      } else {
          this.mobile = true;
          this.icono = 'unfold';
      }
  }

  ngOnInit() {}
}