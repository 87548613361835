<div id="Footer" nz-row>
    <div nz-col [nzXs]="{span:20, offset:2}" style="padding-top:25px;">
        <div nz-row>
            <div nz-col [nzXs]="{span:24, offset:0}" [nzSm]="{span:15, offset:0}" [nzXl]="{span:15, offset:0}">
                <div class="title-rrss">
                    Síguenos en:
                    <div class="rrss-icons">
                        <a href="https://www.facebook.com/ToyotaBoliviaoficial" target="_blanck">
                            <img src="../../assets/images/footer/facebook.svg" alt="Facebook" class="icons-rs"/>
                        </a>
                        <a href="https://www.instagram.com/toyota_bolivia/" target="_blanck">
                            <img src="../../assets/images/footer/instagram.svg" alt="Instagram" class="icons-rs"/>
                        </a>
                        <a href="https://youtube.com/channel/UCfYsmVjP96GrQs0_XbdZoug" target="_blanck">
                            <img src="../../assets/images/footer/youtube.svg" alt="Youtube" class="icons-rs"/>
                        </a>
                        <a href="https://www.linkedin.com/company/toyota-bolivia/" target="_blanck">
                            <img src="../../assets/images/footer/linkedin.svg" alt="LinkedIn" class="icons-rs"/>
                        </a>
                        <a href="https://www.tiktok.com/@toyota_bolivia" target="_blanck">
                            <img src="../../assets/images/footer/tiktok.svg" alt="Tiktok" class="icons-rs"/>
                        </a>
                    </div>
                </div>
            </div>
            <div nz-col [nzXs]="{span:24, offset:0}" [nzSm]="{span:9, offset:0}" [nzXl]="{span:9, offset:0}">
                <a href="tel:591800175200" target="_blanck" >
                    <img src="../../assets/images/footer/contact-center.svg" alt="Contact center" class="icons-video"/>
                </a>
            </div>
        </div>
    </div>
    <nz-divider></nz-divider>
    <div nz-col [nzXs]="{span:20, offset:2}" style="padding-top:0px; padding-bottom: 25px;">
        <div nz-row>
            <div nz-col [nzXs]="{span:8, offset:0}" [nzSm]="{span:4, offset:0}" [nzXl]="{span:4, offset:0}">
                <div class="item-copy">
                    <a href="https://www.toyosa.com/politicas-privacidad" class="link-foot">POLÍTICA DE PRIVACIDAD</a>
                </div>
            </div>
            <div nz-col [nzXs]="{span:8, offset:0}" [nzSm]="{span:4, offset:0}" [nzXl]="{span:4, offset:0}">
                <div class="item-copy">
                    <a href="https://www.toyosa.com/condiciones-uso" class="link-foot">TÉRMINOS Y CONDICIONES</a>
                </div>
            </div>
            <div nz-col [nzXs]="{span:8, offset:0}" [nzSm]="{span:4, offset:0}" [nzXl]="{span:4, offset:0}">
                <div class="item-copy">
                    <a href="https://www.toyosa.com/mapa-del-sitio" class="link-foot">MAPA DE SITIO</a>
                </div>
            </div>
            <div nz-col [nzXs]="{span:24, offset:0}" [nzSm]="{span:12, offset:0}" [nzXl]="{span:12, offset:0}" class="subfoot">
                © {{anio}} <a class="foot-copyright_link" href="https://www.toyosa.com" target="blank">Toyosa</a> copyright. all rights reserved
            </div>
        </div>
    </div>
</div>