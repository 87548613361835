import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.css']
})
export class FiltrosComponent {
/* Este componente es el encargado de aplicar los  filtros de las diferentes marcas
        * tambien contabiliza la cantidad de noticias que hay por marca
          * recibe toda las noticias en la variable data
          * emite al componente padre en la variable select si alguna marca a sido seleccionada
          */
@Input() data: any;
@Output() select: EventEmitter<any> = new EventEmitter();    
marcaActiva:any;
constructor() {
  this.marcaActiva = "Todas las "
  
}
// Función que recibe la marca y emite un evento hacia el elemento padre que indica por que criterio(marca) filtrar las noticias
public selectMarca(MarcaSelect: any, posicion: number) {
    this.select.emit(MarcaSelect); 
    console.log(MarcaSelect);
    if (MarcaSelect == "") {
      this.marcaActiva = "Todas las "
    } else {
      this.marcaActiva = MarcaSelect.toUpperCase();   
    }    
    
    const botones = document.querySelectorAll('.filtrosCategoriasBtn');
    botones.forEach(index => {
      index.classList.remove('active');          
    });

    this.activar(posicion);
}

activar(posicion:any) {
  document.querySelectorAll('.filtrosCategoriasBtn')[posicion].classList.add('active');
}

ngOnInit() {
  const url = location.pathname;
  console.log(url);
  switch(url) {
    case '/lexus':
      this.activar(1);
      break;
    case '/toyota':
      this.activar(2);
      break;
    case '/hino':
      this.activar(3);
      break;
    case '/yamaha':
      this.activar(4);
      break;
    case '/':
      this.activar(0);
      break;
    default:
      
      break;
  }
  
}

}
