import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.css']
})
export class BuscadorComponent {
/*
    Este componente recibe la marca actual por la cual se esta filtrando para generar el slogan de cada una
    y el array con todas las noticias para poder generar el cuadro de busqueda sin necesidad de ningun servicio
 */
    @Input() marca:any;
    @Input() data:any;
    search:any = null;
    visibleSearch:any = false;
    constructor() {}

    // Función que muestra ú oculta la lista del buscador
    buscar() {
        if (this.search.length > 0) {
            this.visibleSearch = true;
        } else {
            this.visibleSearch = false;
        }
    }
    ngOnInit() {}

}
